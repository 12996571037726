<script setup lang="ts">
import {ref} from 'vue';
import {RouterLink} from 'vue-router';

const primary = ref('rgb(var(--v-theme-primary))');
const secondary = ref('rgb(var(--v-theme-secondary))');
import integrahub from "~/assets/logos/integrahub_light_sideways/SVG Vector Files/Transparent Logo.svg";
import integrahub_logo from "~/assets/logos/integrahub_light_sideways/SVG Vector Files/Transparent Logo Only.svg";
import integraanalytics from "~/assets/logos/integraanalytics_light_sideways/SVG Vector Files/Transparent Logo.svg";
import integraanalytics_logo from "~/assets/logos/integraanalytics_light_sideways/SVG Vector Files/Transparent Logo Only.svg";
import integraleads from "~/assets/logos/integraleads_light_sideways/SVG Vector Files/Transparent Logo.svg";
import integraleads_logo from "~/assets/logos/integraleads_light_sideways/SVG Vector Files/Transparent Logo Only.svg";
import {useCustomizerStore} from "~/stores/customizer";

const customizer = useCustomizerStore();
const router = useRouter();
const imgAlt = ref('Integrahub');
const imgSrc = ref(integrahub_logo);
const typeOfRoute = computed(() => {
  const path = router.currentRoute.value.path
  if (path.includes('integrations') || path.includes('apps')) {
    imgAlt.value = 'Integrahub'
    imgSrc.value = customizer.getMiniSidebar && !customizer.getMiniHover ? integrahub_logo : integrahub
    return 'hub'
  } else if (path.includes('analytics')) {
    imgAlt.value = 'Integraanalytics'
    imgSrc.value = customizer.getMiniSidebar && !customizer.getMiniHover ? integraanalytics_logo : integraanalytics
    return 'analytics'
  } else if (path.includes('leads')) {
    imgAlt.value = 'Integraleads'
    imgSrc.value = customizer.getMiniSidebar && !customizer.getMiniHover ? integraleads_logo : integraleads
    return 'leads'
  } else {
    return 'hub'
  }
});
</script>
<template>
    <NuxtLink :to="typeOfRoute === 'hub' ? '' : '/'">
      <img :height="customizer.getMiniSidebar && !customizer.getMiniHover ? 46 : 60"
           :src="imgSrc"
           :alt="imgAlt"/>
    </NuxtLink>
</template>
