<script setup lang="ts">
import {shallowRef} from 'vue';
import {useCustomizerStore} from '@/stores/customizer';
import {sidebarDevelopment} from './sidebarDefault';

const customizer = useCustomizerStore();
const sidebarMenuDevelopment = shallowRef(sidebarDevelopment);
</script>

<template>
  <v-navigation-drawer
      left
      v-model="customizer.Sidebar_drawer"
      elevation="0"
      rail-width="75"
      :mobile-breakpoint="960"
      app
      class="leftSidebar"
      :rail="customizer.mini_sidebar"
      :touchless="true"
      expand-on-hover width="270"
      @mouseenter="customizer.SET_MINI_HOVER(true)"
      @mouseleave="customizer.SET_MINI_HOVER(false)"
  >
    <!---Logo part -->
    <v-locale-provider v-if="customizer.setRTLLayout" rtl>
      <div class="pa-5">
        <LcFullLogoRtlLogo/>
      </div>
    </v-locale-provider>
    <v-locale-provider v-else>
      <div class="mt-6" :class="customizer.getMiniSidebar && !customizer.getMiniHover ? 'ml-4' : 'ml-6' ">
        <LcFullLogo/>
      </div>
    </v-locale-provider>

    <!-- ---------------------------------------------- -->
    <!---Navigation -->
    <!-- ---------------------------------------------- -->
    <perfect-scrollbar class="scrollnavbar">
      <v-list class="pa-6" density="compact">
        <!---Menu Loop -->
        <template v-for="(item, i) in customizer.getSidebarItems">
          <!---Item Sub Header -->
          <LcFullVerticalSidebarNavGroup :item="item" v-if="item.header" :key="item.title"/>
          <!---If Has Child -->
          <LcFullVerticalSidebarNavCollapse class="leftPadding" :item="item" :level="0" v-else-if="item.children"/>
          <!---Single Item-->
          <LcFullVerticalSidebarNavItem :item="item" v-else class="leftPadding"/>
          <!---End Single Item-->
        </template>
        <!---Menu Loop -->
        <template v-for="(item, i) in sidebarMenuDevelopment" v-if="customizer.getDevelopmentSidebarItems">
          <!---Item Sub Header -->
          <LcFullVerticalSidebarNavGroup :item="item" v-if="item.header" :key="item.title"/>
          <!---If Has Child -->
          <LcFullVerticalSidebarNavCollapse class="leftPadding" :item="item" :level="0" v-else-if="item.children"/>
          <!---Single Item-->
          <LcFullVerticalSidebarNavItem :item="item" v-else class="leftPadding"/>
          <!---End Single Item-->
        </template>
      </v-list>
      <div class="pa-6 userbottom">
        <LcFullVerticalSidebarProfile/>
      </div>
    </perfect-scrollbar>
  </v-navigation-drawer>
</template>
