<script setup lang="ts">
import {useDisplay} from "vuetify";

const router = useRouter();
const integraanalyticsRoute = computed(() => {
  return router.currentRoute.value.path.includes('analytics');
});
const integraleadsRoute = computed(() => {
  return router.currentRoute.value.path.includes('leads');
});
const inAppRoute = computed(() => {
  let path = router.currentRoute.value.path
  return path.includes('integrations') || path.includes('apps');
});
</script>

<template>
  <SharedHubLogoAnimation v-if="inAppRoute" />
  <SharedAnalyticsLogoAnimation v-else-if="integraanalyticsRoute" />
  <SharedLeadsLogoAnimation v-else-if="integraleadsRoute" />
</template>

<style scoped>

</style>