<script setup lang="ts">
import {MailIcon} from "vue-tabler-icons";
import {auth0LogoutUrl} from "@/utils/helpers/auth0-url";
import {useResetStore} from '@/utils/useResetStore'
import HubLogoRedirectAnimation from "~/components/shared/HubLogoRedirectAnimation.vue";

const {data, signOut} = useAuth();
const user = useUserStore();
const { getUser } = storeToRefs(user);
const router = useRouter();
const resetStore = useResetStore()
const isLoading = ref(false);

const resetPassword = async () => {
  const url: string = await user.resetPassword();
  window.open(url, '_blank')
}

const logout = async () => {
  isLoading.value = true;
  await new Promise(resolve => setTimeout(resolve, 1000));
  // Reset all stores
  resetStore.all()
  signOut({callbackUrl: auth0LogoutUrl()});

}
</script>

<template>
  <!-- ---------------------------------------------- -->
  <!-- notifications DD -->
  <!-- ---------------------------------------------- -->
  <v-menu :close-on-content-click="true">
    <template v-slot:activator="{ props }">
      <v-btn class="custom-hover-primary" variant="text" v-bind="props" icon>
        <v-avatar size="35">
          <img :src="data?.user?.image || ''" width="35" :alt="getUser?.name || ''"/>
        </v-avatar>
      </v-btn>
    </template>
    <v-sheet rounded="md" width="360" elevation="10">
      <div class="px-8 pt-6">
        <h6 class="text-h5 font-weight-medium">Benutzer</h6>
        <div class="d-flex align-center mt-4 pb-6">
          <v-avatar size="80">
            <img :src="data?.user?.image || ''" width="80"/>
          </v-avatar>
          <div class="ml-3">
            <h6 class="text-h6 mb-n1">{{ getUser?.name }}</h6>
            <div class="d-flex align-center mt-1">
              <MailIcon size="18" stroke-width="1"/>
              <span class="text-subtitle-1 font-weight-regular textSecondary ml-2">{{ getUser?.email }}</span>
            </div>
          </div>
        </div>
<!--          <v-btn color="secondary" variant="text" :block="true" @click="resetPassword">Passwort ändern</v-btn>-->
      </div>
      <div class="pb-8 px-8 text-center">
        <v-btn color="primary" :block="true" :loading="isLoading" @click="router.push({ path: '/auth/settings' })"><SettingsIcon size="20" class="mr-2" />Einstellungen</v-btn>
        <v-btn class="mt-2" color="primary" variant="tonal" :block="true" :loading="isLoading" @click="logout"><LogoutIcon size="20" class="mr-2"/>Ausloggen</v-btn>
      </div>
    </v-sheet>
  </v-menu>
  <v-overlay scroll-strategy="none" scrim="white" :disabled="true" :opacity="1" :model-value="isLoading"
             :persistent="true" class="d-flex justify-center align-center flex-column">
    <HubLogoRedirectAnimation title="Account wird abgemeldet"/>
  </v-overlay>
</template>
