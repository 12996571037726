<script setup lang="ts">
import { MoonIcon, MoonOffIcon } from 'vue-tabler-icons'
import { useCustomizerStore } from '@/stores/customizer';

const customizer = useCustomizerStore();
const darkMode = ref(false);
</script>
<template>
  <!-- ---------------------------------------------- -->
  <!-- DARK MODE DD -->
  <!-- ---------------------------------------------- -->
  <v-btn @click="darkMode = !darkMode; customizer.SET_THEME(darkMode ? 'DARK_BLUE_THEME' : 'BLUE_THEME')" icon
    variant="text" color="primary">
    <MoonIcon v-if="darkMode" stroke-width="1.5" size="22" />
    <MoonOffIcon v-else stroke-width="1.5" size="22" />
  </v-btn>
</template>
