<script setup lang="ts">
import { ref } from "vue";
import { auth0LogoutUrl } from "@/utils/helpers/auth0-url";
import { PowerIcon } from "vue-tabler-icons";
import { useResetStore } from '@/utils/useResetStore'
import HubLogoRedirectAnimation from "~/components/shared/HubLogoRedirectAnimation.vue";

const { data, signOut } = useAuth();
const user = useUserStore();
const { getUser } = storeToRefs(user);
const resetStore = useResetStore()
const isLoading = ref(false)
const logout = async () => {
  isLoading.value = true;
  await new Promise(resolve => setTimeout(resolve, 1000));
  // Reset all stores
  resetStore.all()
  signOut({ callbackUrl: auth0LogoutUrl() });
}
</script>

<template>
  <v-sheet rounded="md" color="lightsecondary" class="px-2 py-3 ExtraBox">
    <div class="d-flex align-center">
      <v-avatar size="40">
        <img :src="data?.user?.image || ''" alt="user" height="40" />
      </v-avatar>
      <div class="ml-4" style="max-width: 50%;">
        <h4 class="mb-n1 text-h6 textPrimary cut-text">{{ getUser?.name }}</h4>
        <p class="text-subtitle-2 textSecondary cut-text">{{ getUser?.email }}</p>
      </div>
      <div class="ml-auto">
        <v-btn variant="text" icon rounded="md" :loading="isLoading" color="primary" @click="logout">
          <PowerIcon />
          <v-tooltip activator="parent" location="top">Ausloggen</v-tooltip>
        </v-btn>
      </div>
    </div>
  </v-sheet>
  <!--Content-->
  <v-overlay scroll-strategy="none" scrim="white" disabled :opacity="1" :model-value="isLoading" persistent class="d-flex justify-center align-center flex-column">
    <HubLogoRedirectAnimation title="Account wird abgemeldet" />
  </v-overlay>
  <!-- <div v-if="isLoading" style="position: fixed; height: 100vh; width: 100vw; z-index: 999" class="d-flex justify-center align-center flex-column">
    <HubLogoRedirectxAnimation title="Account wird abgemeldet" />
  </div> -->
</template>
<style lang="scss">
.ExtraBox {
  position: relative;
  overflow: hidden;
}

.line-height-none {
  line-height: normal;
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
