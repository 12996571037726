<script setup lang="ts">
import { BugIcon, BugOffIcon } from 'vue-tabler-icons'
import {useCustomizerStore} from '@/stores/customizer';
const customizer = useCustomizerStore();
const developmentSidebar = ref(false);
</script>
<template>
  <!-- ---------------------------------------------- -->
  <!-- DEVELOPMENT MODE DD -->
  <!-- ---------------------------------------------- -->
  <v-btn @click="developmentSidebar = !developmentSidebar; customizer.SET_DEVELOPMENT_SIDEBAR_ITEMS(developmentSidebar)" icon
         variant="text" color="primary">
    <BugIcon v-if="developmentSidebar" stroke-width="1.5" size="22"/>
    <BugOffIcon v-else stroke-width="1.5" size="22"/>
  </v-btn>
</template>
